<template>
  <div :class="['mobile-nav', { 'open' : mobileNavOpen }]">
    <div class="nav-header">
      <TheLogo class="header-logo" />
      <button aria-label="close mobile" class="mobile-nav-button-close" @click="closeMobileNav">
        <BRIcon class="mobile-nav-button-close-icon" icon="close" fill-color="var(--logo-color)" />
      </button>
    </div>
    <div class="nav-main">
      <v-expansion-panels
        v-model="casinos"
        class="nav-item"
        active-class="nav-item--active"
        accordion
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="nav-item-header">
            Casinos
            <template #actions>
              <v-icon class="nav-item-arrow-icon">
                mdi-menu-down
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="nav-item-content">
            <ul class="nav-item-content-list">
              <li
                v-for="(currency, index) in $contentful.currencies"
                :key="index"
              >
                <router-link
                  class="nav-item-link"
                  :to="{ name: 'CasinoCurrency', params: { slug: currency.slug } }"
                >
                  {{ currency.name }}
                </router-link>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <router-link active-class="active" class="nav-item-header" :to="{ name: 'Airdrop' }">
        Airdrops
      </router-link>

      <v-expansion-panels
        v-for="(category, index) in tagCategories"
        :key="category"
        v-model="tagsModels[index]"
        class="nav-item"
        active-class="nav-item--active"
        accordion
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="nav-item-header">
            {{ category }}
            <template #actions>
              <v-icon class="nav-item-arrow-icon">
                mdi-menu-down
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="nav-item-content">
            <ul class="nav-item-content-list">
              <li
                v-for="tag in tags[category]"
                :key="tag.id"
              >
                <router-link
                  class="nav-item-link"
                  :to="{ name: 'CasinoTag', params: { slug: tag.slug } }"
                >
                  {{ tag.name }}
                </router-link>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <router-link active-class="active" class="nav-item-header" :to="{ name: 'About' }">
        <span>About Us</span>
      </router-link>
    </div>

    <div class="nav-footer">
      <button v-if="getUserDB">
        <BRIcon class="nav-footer-icon" icon="bell" />
      </button>
      <BRButton
        v-if="getUserDB"
        height="40"
        :to="{ name: 'Profile' }"
        dark
        class="profile-button"
      >
        Profile
      </BRButton>

      <BRButton
        v-else
        :to="{
          name: 'Login',
          query: { redirect: $route.path }
        }"
        height="40"
        dark
        class="profile-button"
      >
        Sign In
      </BRButton>

      <button class="theme-switch" @click="isDarkTheme = !isDarkTheme">
        <BRIcon class="nav-footer-icon" color="var(--secondary-text-color)" icon="theme-switch" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import changeTheme from '@/mixins/changeTheme'
import scrollBlock from '@/mixins/scrollBlock'
import TheLogo from '@/components/TheLogo.vue'

export default {
  name: 'MobileNav',
  components: {
    TheLogo
  },
  mixins: [changeTheme, scrollBlock],
  props: {
    mobileNavOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tags: this.$contentful.tags,
      casinos: null,
      tagsModels: []
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    tagCategories() {
      return Object.keys(this.tags).sort()
    }
  },
  watch: {
    $route() {
      this.casinos = null
      this.tagsModels = []
      this.closeMobileNav()
    },
    mobileNavOpen() {
      if (this.mobileNavOpen) {
        this.blockScroll()
      } else {
        this.unblockScroll()
      }
    }
  },
  methods: {
    closeMobileNav() {
      this.$emit('update:mobileNavOpen', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-nav {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 105;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 29px 23px 32px;
  width: 100%;
  max-width: 330px;
  box-shadow: none;
  background-color: var(--content-bg-color);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  overscroll-behavior: contain;

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    padding-right: 6px;
    padding-left: 12px;
    gap: 20px;

    .header-logo {
      width: 138px;
    }

    .mobile-nav-button-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      .mobile-nav-button-close-icon {
        width: 12px;
        height: 12px;
      }
    }
  }

  .nav-main {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .nav-item {
      background-color: transparent;

      &::v-deep .v-expansion-panel {
        background-color: transparent;
      }
    }

    .nav-item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      padding: 15px 9px 15px 19px;
      min-height: initial;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-base);
      color: var(--secondary-text-color);
      background-color: var(--bg-color);
      transition: background-color 0.3s ease-out, color 0.3s ease-out;

      &.active,
      &:focus-visible {
        outline: none;
        color: var(--primary-text-color);
        background-color: var(--primary-bg-color);
        transition: background-color 0.3s ease-in, color 0.3s ease-in;
      }
    }

    .nav-item-arrow-icon {
      color: inherit !important;
    }

    .nav-item--active .nav-item-header {
      color: var(--primary-text-color);
      background-color: var(--primary-bg-color);
      transition: background-color 0.3s ease-in, color 0.3s ease-in;
    }

    .nav-item-content {
      &::v-deep .v-expansion-panel-content__wrap {
        padding: 17px 15px;
      }

      .nav-item-content-list {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }

      .nav-item-link {
        display: block;
        border-radius: 5px;
        padding: 15px 14px 14px;
        min-height: initial;
        font-weight: var(--font-weight-bold);
        font-size: var(--font-base);
        color: var(--secondary-text-color);
        background-color: transparent;
        transition: background-color 0.3s ease-out, color 0.3s ease-out;

        &.router-link-active,
        &:focus-visible {
          outline: none;
          color: var(--primary-text-color);
          background-color: var(--primary-bg-color);
          transition: background-color 0.3s ease-in, color 0.3s ease-in;
        }
      }
    }
  }

  .nav-footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 0 10px;
    width: 100%;
    gap: 30px;

    .profile-button {
      flex: 1 1 100%;
    }

    .nav-footer-icon {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }
  }

  &.open {
    box-shadow: var(--secondary-box-shadow);
    transform: translateX(0);
    transition: transform 0.3s ease-in;
  }
}

[data-theme="dark"] {
  .mobile-nav .nav-main .nav-item-header {
    background-color: var(--secondary-button-bg-color);

    &.active,
    &:focus-visible {
      outline: none;
      color: var(--primary-text-color);
      background-color: var(--primary-bg-color);
      transition: background-color 0.3s ease-in, color 0.3s ease-in;
    }
  }

  .mobile-nav .nav-main .nav-item--active .nav-item-header {
    color: var(--primary-text-color);
    background-color: var(--primary-bg-color);
    transition: background-color 0.3s ease-in, color 0.3s ease-in;
  }
}
</style>
