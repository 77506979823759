<template>
  <v-menu
    nudge-left="60"
    content-class="notifications-list"
    bottom
    open-on-hover
    :disabled="!notificationsLength"
    :close-on-content-click="false"
    max-width="349"
    nudge-bottom="42"
  >
    <template #activator="{ on, attrs }">
      <div :class="{ active: attrs['aria-expanded'] === 'true' }" class="bell-container" v-on="on">
        <v-badge
          color="var(--notification-badge-color)"
          dot
          class="badge"
          :value="notificationsLength"
        >
          <BRIcon class="bell" icon="bell" />
        </v-badge>
      </div>
    </template>
    <v-list min-width="260" class="dropdown-notification-list">
      <div
        v-for="(notificationItem, i) in visibleNotifications"
        :key="i"
        class="dropdown-notification-list-item-wrapper"
        @click="readNotification(notificationItem.id)"
      >
        <div class="dropdown-notification-list-item">
          <span class="notification-title">{{ notificationItem.title }}</span>
          <p class="notification-text">
            {{ notificationItem.text }}
          </p>
        </div>
      </div>
      <button v-if="isShowMoreButton" class="show-all-button" @click="showNotificationsToggle">
        Show all
      </button>
      <button v-if="isShowHideButton" class="hide-button" @click="showNotificationsToggle">
        Hide
      </button>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'HeaderNotifications',
  data() {
    return {
      notifications: [],
      previewItemsCount: 3,
      isHideAllNotifications: true
    }
  },
  computed: {
    notificationsLength() {
      return this.notifications.length
    },
    isShowMoreButton() {
      return this.notifications.length > this.previewItemsCount && this.isHideAllNotifications
    },
    isShowHideButton() {
      return this.notifications.length > this.previewItemsCount && !this.isShowMoreButton
    },
    visibleNotifications() {
      return this.isHideAllNotifications ? this.notifications.slice(0, this.previewItemsCount) : this.notifications
    }
  },
  created() {
    this.getNotifications()
  },
  methods: {
    async getNotifications() {
      // TODO: Implement
      // const { data, error } = await api.users.getNotifications()
      //
      // if (!error) { this.notifications = data }
    },
    async readNotification(notificationId) {
      // TODO: Implement
      // const { error } = await api.users.readNotification(notificationId)
      //
      // if (!error) {
      //   await this.getNotifications()
      // }
    },
    showNotificationsToggle() {
      this.isHideAllNotifications = !this.isHideAllNotifications
    }
  }
}
</script>

<style lang="scss" scoped>
.bell-container {
  display: flex;
  justify-content: center;
  min-width: 65px;

  .badge::v-deep .v-badge__badge {
    box-shadow: 0 0 8px 1px var(--notification-badge-color);
  }
}

.bell {
  display: block;
  margin: 0 auto;
}

.notifications-list {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 500px;

  &::v-deep.v-menu__content {
    border-radius: var(--border-radius-default);
    padding: 13px;
    box-shadow: var(--box-shadow-default);
    background-color: var(--content-bg-color);
  }

  .dropdown-notification-list {
    padding: 0;
    background-color: var(--content-bg-color);
  }

  .dropdown-notification-list-item-wrapper {
    border-bottom: var(--default-border);
    padding-top: 10px;
    padding-bottom: 8px;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .dropdown-notification-list-item {
    border-radius: var(--border-radius-default);
    padding: 12px;
    max-height: 87px;

    .notification-title {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-base);
      color: var(--text-color);
    }

    .notification-text {
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      overflow: hidden;
      margin-bottom: 5px;
      font-size: var(--font-base);
      text-overflow: ellipsis;
      color: var(--secondary-text-color);
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    &:hover,
    &:focus {
      background-color: var(--tertiary-bg-color);
    }
  }

  .hide-button,
  .show-all-button {
    display: block;
    margin: 24px auto 0;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--primary-text-color);
  }
}
</style>
