import { callPrivateApi } from './apiHelper'

const USER_HANDLER = '/user'

export const getUser = async() => {
  return callPrivateApi('get', USER_HANDLER)
}

export const updateUser = async(user) => {
  return callPrivateApi('put', `${USER_HANDLER}`, user)
}

export const uploadImage = async(file) => {
  // TODO: Implement image upload
}

export const getNotifications = async() => {
  // TODO: Implement
  return []
}

export const getLikedCasinos = async(type, {
  pageSize,
  pageNumber
}) => {
  const params = {
    pp: pageSize,
    p: pageNumber
  }

  return callPrivateApi('get', `${USER_HANDLER}/likes/${type}`, { params })
}
