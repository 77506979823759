import { callPrivateApi } from '@/api/apiHelper'

const LIKES_HANDLER = '/likes'

export const getLikes = async(ids) => {
  return callPrivateApi('get', `${LIKES_HANDLER}`, { params: { ids } })
}

// Types - casino, airdrop
export const toggleLike = async(itemId, type) => {
  return callPrivateApi('post', `${LIKES_HANDLER}`, {
    itemId,
    type
  })
}
