<template>
  <header
    class="header"
    :class="{ 'header--hidden': isHeaderHidden }"
  >
    <div class="content">
      <router-link class="item" to="/">
        <TheLogo />
      </router-link>

      <div class="links">
        <CurrenciesMenu open-on-hover>
          <template #activator-element>
            <router-link
              :class="['item-wrapper', {'active' : routeName === 'CasinoCurrency' || routeName === 'Root'}]"
              to="/"
            >
              <span class="item">Casinos</span>
            </router-link>
          </template>
        </CurrenciesMenu>

        <router-link active-class="active" class="item-wrapper" :to="{ name: 'Airdrop' }">
          <span class="item">Airdrops</span>
        </router-link>

        <TagsMenu open-on-hover>
          <template #activator-element>
            <button :class="['item-wrapper', {'active' : routeName === 'CasinoTag'}]" type="button">
              <span class="item">More</span>
            </button>
          </template>
        </TagsMenu>

        <router-link active-class="active" class="item-wrapper" :to="{ name: 'About' }">
          <span class="item">About Us</span>
        </router-link>
      </div>

      <div v-if="getUserDB" class="profile">
        <HeaderNotifications />
        <BRButton
          height="40"
          :to="{ name: 'Profile' }"
          dark
          class="profile-button"
        >
          Profile
        </BRButton>
      </div>

      <div v-else class="profile">
        <BRButton
          :to="{
            name: 'Login',
            query: { redirect: $route.path }
          }"
          height="40"
          dark
          class="profile-button sign-in"
        >
          Sign In
        </BRButton>
      </div>

      <div class="theme-switch" @click="isDarkTheme = !isDarkTheme">
        <BRIcon color="var(--theme-switch-icon-color)" icon="theme-switch" />
      </div>

      <button class="mobile-nav-button" @click="openMobileNav">
        <BRIcon icon="mobile-menu-icon" fill-color="var(--logo-color)" />
      </button>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import throttle from '@/services/throttle'
import changeTheme from '@/mixins/changeTheme'
import TheLogo from '@/components/TheLogo'
import TagsMenu from '@/components/TagsMenu.vue'
import HeaderNotifications from '@/components/HeaderNotifications'
import CurrenciesMenu from '@/components/CurrenciesMenu.vue'

export default {
  name: 'TheHeader',
  components: {
    CurrenciesMenu,
    TheLogo,
    TagsMenu,
    HeaderNotifications
  },
  mixins: [changeTheme],
  props: {
    mobileNavOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      handleScrollThrottled: null,
      previousScrollPosition: 0,
      isHeaderHidden: false
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    routeName() {
      return this.$route.name
    }
  },
  mounted() {
    this.handleScrollThrottled = throttle(this.handleScroll)
    window.addEventListener('scroll', this.handleScrollThrottled)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollThrottled)
  },
  methods: {
    ...mapActions(['setUser']),
    openMobileNav() {
      this.$emit('update:mobileNavOpen', true)
    },
    handleScroll() {
      const currentScrollPosition = window.pageYOffset
      this.isHeaderHidden = !!(this.previousScrollPosition && (currentScrollPosition > this.previousScrollPosition))
      this.previousScrollPosition = currentScrollPosition
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  flex-grow: 0;
  padding: 4px 16px;
  height: 72px;
  box-shadow: none !important;
  background-color: var(--content-bg-color) !important;
  transform: translateY(0);
  transition: transform 0.5s ease-in-out;

  &--hidden {
    transform: translateY(-100%);
  }

  @include breakpoint-reverse(small) {
    padding: 22px 27px;
    height: 65px;
  }
}

.content {
  @include container;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.links {
  display: grid;
  grid-template-columns: repeat(4, auto);
  margin-left: auto;
  padding: 0 22px;

  .item-wrapper.active {
    .item {
      color: var(--primary-text-color);
      background-color: var(--primary-bg-color);
    }
  }

  .br-menu__activator.br-menu__activator_active {
    .item-wrapper {
      background-color: var(--primary-bg-color);
    }

    .item {
      color: var(--primary-text-color);
    }
  }

  .item-wrapper,
  &::v-deep .item-wrapper {
    .item {
      display: block;
      border-radius: 5px;
      padding: 9px 22px;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-base);
      white-space: nowrap;
      color: var(--secondary-text-color);
      transition-duration: 0.2s;
      user-select: none;

      &:hover {
        color: var(--primary-text-color);
        background-color: var(--primary-bg-color);
      }
    }

    &.active {
      background-color: var(--primary-bg-color);
    }
  }

  @include breakpoint-reverse(small) {
    display: none;
  }
}

.logo {
  display: flex;
  margin-right: 40px;
  width: 150px;
}

.profile {
  display: flex;
  align-items: center;
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  padding: 0 18px 0 0;

  .blockreviews-button.profile-button {
    padding: 0 23px;
  }

  .blockreviews-button.sign-in {
    margin-left: 18px;
    padding: 0 23px;
  }

  @include breakpoint-reverse(small) {
    display: none;
  }
}

.theme-switch {
  margin-left: 18px;
  height: 16px;
  cursor: pointer;
  user-select: none;

  &::v-deep .blockreviews-icon {
    color: var(--secondary-text-color);
  }

  @include breakpoint-reverse(small) {
    display: none;
  }
}

.mobile-nav-button {
  display: none;
  width: 20px;
  height: 16px;

  @include breakpoint-reverse(small) {
    display: flex;
  }
}
</style>
