<template>
  <v-app class="app-wrapper">
    <div
      v-if="mobileNavOpen"
      class="shadow"
      @click="closeMobileNav"
    />
    <TheHeader :mobile-nav-open.sync="mobileNavOpen" />
    <v-main class="content">
      <transition mode="out-in" appear name="fade-transition">
        <v-container fluid class="container">
          <router-view v-if="!getIsNotFound" class="view" />
          <ErrorPage v-if="getIsNotFound" />
        </v-container>
      </transition>
    </v-main>
    <TheFooter />
    <MobileNav :mobile-nav-open.sync="mobileNavOpen" />
    <CookiePopup />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import TheFooter from '@/components/TheFooter'
import TheHeader from '@/components/TheHeader'
import MobileNav from '@/components/MobileNav'
import CookiePopup from '@/components/CookiePopup'
import ErrorPage from '@/components/ErrorPage.vue'
export default {
  name: 'TargetPage',
  components: {
    TheFooter,
    TheHeader,
    MobileNav,
    CookiePopup,
    ErrorPage
  },
  data() {
    return {
      mobileNavOpen: false
    }
  },
  computed: {
    ...mapGetters(['getIsNotFound'])
  },
  methods: {
    closeMobileNav() {
      this.mobileNavOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.container {
  padding: 28px 20px 44px;

  @include breakpoint(medium) {
    padding: 50px 20px 74px;
  }
}

.app-wrapper {
  min-height: 100vh;
  font-family: inherit;
  line-height: inherit;
}

.content {
  background-color: var(--bg-color);

  &::v-deep .v-main__wrap {
    display: grid;
    grid-template-rows: max-content;
  }
}

.view {
  min-height: calc(100% - 364px);
}

.shadow {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
</style>
