<template>
  <v-footer
    class="footer pa-4 d-flex justify-space-between"
    tag="footer"
  >
    <div class="content">
      <div class="information">
        <v-row>
          <v-col cols="12" sm="6" md="5">
            <div class="description">
              <TheLogo />
              <div class="text">
                <p>
                  Warning. BlockReviews don’t give you any warranty about every
                  website that you can find on the blockreview.com. This website
                  shows only a subjective opinion of website owners and opinion of
                  other users.
                </p>
                <p>
                  Blockreviews is not responsible for other sites and for any
                  transactions and actions that you can do on this websites. Read
                  <router-link to="/">
                    «Aggreement»
                  </router-link>.
                </p>
              </div>
              <SocialsList :socials="socials" class="socials-list" />
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <div class="links">
              <h4>Company</h4>
              <div class="list">
                <router-link class="item" :to="{ name: 'About' }">
                  About Us
                </router-link>
                <router-link class="item" to="/">
                  How it works?
                </router-link>
                <router-link class="item" :to="{ name: 'Privacy' }">
                  Terms of use
                </router-link>
                <router-link class="item" :to="{ name: 'Faq' }">
                  FAQ
                </router-link>
                <router-link class="item" :to="{ name: 'Privacy' }">
                  Privacy Policy & Cookies
                </router-link>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <div class="links">
              <h4>For Partners</h4>
              <div class="list">
                <router-link class="item" to="/">
                  Get listed (for ICO)
                </router-link>
                <router-link class="item" to="/">
                  Work with us
                </router-link>
                <router-link class="item" to="/">
                  For Casinos
                </router-link>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <div class="links">
              <h4>Contact</h4>
              <div class="list">
                <span class="item">
                  <BRIcon
                    themed
                    class="icon"
                    icon="flag"
                    width="16"
                    height="16"
                    fill-color="var(--footer-icon-color)"
                  /> New York, Wall Street 20, 30-510
                </span>
                <a class="item" :href="`mailto:${contacts.email}`">
                  <BRIcon
                    themed
                    class="icon"
                    icon="email"
                    width="16"
                    height="16"
                    fill-color="var(--footer-icon-color)"
                  />
                  {{ contacts.email }}
                </a>
                <a class="item" :href="`tel:${contacts.phone}`">
                  <BRIcon
                    themed
                    class="icon"
                    icon="phone"
                    width="16"
                    height="16"
                    fill-color="var(--footer-icon-color)"
                  />
                  {{ contacts.phone }}
                </a>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <span class="copy-text">All Copyrights reserved {{ getCurrentYear() }}. BlockReviews comp.</span>
    </div>
  </v-footer>
</template>

<script>
import { contacts } from '../../public/messages/contacts'
import TheLogo from '@/components/TheLogo'
import SocialsList from '@/components/SocialsList'

export default {
  name: 'TheFooter',
  components: {
    TheLogo,
    SocialsList
  },
  data() {
    return {
      contacts,
      socials: [
        {
          id: 1,
          icon: 'socials-twitter',
          title: 'twitter',
          link: '#'
        },
        {
          id: 2,
          icon: 'socials-linkedin',
          title: 'linkedin',
          link: '#'
        },
        {
          id: 3,
          icon: 'socials-facebook',
          title: 'facebook',
          link: '#'
        },
        {
          id: 4,
          icon: 'socials-instagram',
          title: 'instagram',
          link: '#'
        }
      ]
    }
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.footer {
  margin-top: auto;
  background-color: var(--content-bg-color) !important;
}

.content {
  @include container;

  padding: 28px 0 0;
  width: 100%;
}

.information {
  display: flex;
  justify-content: space-between;
}

.description {
  .text {
    @include subtitle;

    margin-top: 21px;
    font-size: var(--font-sm);
  }

  .socials-list {
    justify-content: flex-start;
    margin-top: 30px;
  }

  a {
    color: var(--primary-text-color);
  }

  @include breakpoint(medium) {
    max-width: 230px;
  }
}

.links {
  .list {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: var(--font-base);
    color: var(--text-color);

    .icon {
      flex-shrink: 0;
      align-self: flex-start;
      margin-right: 14px;
      width: 16px;
      height: 16px;
    }
  }
}

.copy-text {
  display: block;
  margin-top: 20px;
  font-size: var(--font-sm);
  text-align: center;
  color: var(--secondary-text-color);
}
</style>
