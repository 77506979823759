<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    shape-rendering="geometricPrecision"
    aria-hidden="true"
  >
    <!-- for fill/stroke usage clear this attrs values from svg file -->
    <use
      class="app-icon"
      :fill="fillColor"
      :stroke="strokeColor"
      :href="iconPath"
    />
  </svg>
</template>

<script>
export default {
  name: 'BRIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: '16'
    },
    height: {
      type: String,
      required: false,
      default: '16'
    },
    fillColor: {
      type: String,
      required: false,
      default: ''
    },
    strokeColor: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    iconPath() {
      let icon = require(`@/assets/icons/${this.icon}.svg`)
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }
      return icon
    }
  }
}
</script>
