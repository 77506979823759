import { callPrivateApi } from '@/api/apiHelper'

const ITEMS_HANDLER = '/items'

export const getItemComments = async(itemId, {
  pageNumber: p,
  pageSize: pp,
  sortBy: sb
}) => {
  const params = { p, pp, sb }

  return callPrivateApi('get', `${ITEMS_HANDLER}/${itemId}/comments`, { params })
}

export const getUserComments = async({
  pageNumber,
  pageSize
}) => {
  const params = {
    p: pageNumber,
    pp: pageSize
  }

  return callPrivateApi('get', '/user/comments', { params })
}

export const addUserComment = async(itemId, comment) => {
  return callPrivateApi('post', `${ITEMS_HANDLER}/${itemId}/comments`, comment)
}

export const updateUserComment = async(itemId, comment) => {
  return callPrivateApi('put', `${ITEMS_HANDLER}/${itemId}/comments/${comment.id}`, comment)
}

export const deleteUserComment = async(itemId, commentId) => {
  return callPrivateApi('delete', `${ITEMS_HANDLER}/${itemId}/comments/${commentId}`)
}

export const toggleCommentLike = async(itemId, commentId) => {
  return callPrivateApi('post', `${ITEMS_HANDLER}/${itemId}/comments/${commentId}/likes`)
}
