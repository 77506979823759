import richTextToHtmlString from 'rich-text-to-html'

const contentfulPlugin = {
  install: (Vue, options) => {
    const data = {}
    const VM = new Vue({ data })

    Vue.contentful = VM.$data
    Object.defineProperty(Vue.prototype, '$contentful', {
      get: () => {
        return VM.$data
      }
    })

    data.reviews = options.reviews.map(r => ({
      ...r.fields,
      id: r.sys.id,
      seoImage: r.fields.seoImage?.fields?.file?.url,
      logo: r.fields.logo?.fields?.file?.url,
      currencies: r.fields?.currencies?.map((currencyItem) => ({
        currency: {
          name: currencyItem?.fields?.name,
          icon: currencyItem?.fields?.icon?.fields?.file?.url,
          slug: currencyItem?.fields?.slug,
          id: currencyItem.sys.id
        }
      })),
      depositMethods: r.fields?.depositMethods?.map((depositMethodItem) => (
        {
          name: depositMethodItem?.fields?.name,
          icon: depositMethodItem?.fields?.icon?.fields?.file?.url,
          id: depositMethodItem.sys.id
        }
      )),
      tags: r.fields?.tags?.map((tagItem) => ({
        tag: {
          name: tagItem?.fields?.name,
          slug: tagItem?.fields?.slug,
          id: tagItem.sys.id
        }
      })),
      games: r.fields?.games?.map((gameItem) => (
        {
          name: gameItem?.fields?.name,
          logo: gameItem?.fields?.logo?.fields?.file?.url,
          id: gameItem.sys.id
        }
      )),
      alternatives: r.fields?.alternatives?.map((alternative) => (
        {
          logo: alternative?.fields?.logo?.fields?.file?.url,
          logoAlt: alternative?.fields?.logo?.fields?.title,
          bestBonus: alternative?.fields?.bonuses?.[0]?.fields?.title,
          bonuses: alternative?.fields?.bonuses,
          description: alternative?.fields?.shortDescription,
          rate: alternative?.fields?.totalRating,
          title: alternative?.fields?.name,
          id: alternative.sys.id
        }
      )),
      devices: r.fields?.devices?.map((device) => (
        {
          name: device?.fields?.name,
          id: device.sys.id
        }
      )),
      customerSupport: r.fields?.customerSupport?.map((customerSupport) => (
        {
          name: customerSupport?.fields?.name,
          id: customerSupport.sys.id
        }
      )),
      sliderImages: r.fields?.sliderImages?.map((sliderImage) => (
        sliderImage?.fields?.file?.url
      )),
      bonuses: r.fields?.bonuses?.map((bonuse) => (
        bonuse?.fields
      )),
      countries: r.fields?.countries?.map((country) => (
        {
          name: country?.fields?.name,
          flag: country?.fields?.flag?.fields?.file?.url,
          id: country.sys.id
        }
      ))
    }))

    data.currencies = options.currencies.map(c => ({
      ...c.fields,
      id: c.sys.id,
      icon: c.fields.icon?.fields?.file?.url
    }))

    data.tags = {}
    options.tags.forEach(t => {
      if (!data.tags[t.fields.category]) data.tags[t.fields.category] = []
      data.tags[t.fields.category].push({ ...t.fields, id: t.sys.id })
    })
    for (const key in data.tags) {
      data.tags[key].sort((a, b) => a.name.localeCompare(b.name))
    }

    data.airdrops = options.airdrops.map(a => ({
      ...a.fields,
      id: a.sys.id,
      seoImage: a.fields.seoImage?.fields?.file?.url,
      logo: a.fields.logo?.fields?.file?.url
    }))

    const ads = {}
    options.ads.forEach(a => {
      ads[a.fields.name] = richTextToHtmlString(a.fields.content).join('')
    })
    data.ads = ads

    data.privacyPolicyTermsOfUse = options.privacyPolicyTermsOfUse.map(p => ({
      title: p.fields.title,
      content: p.fields.content
    }))

    data.faqs = options.faq.map((faq) => ({
      title: faq.fields.question,
      text: faq.fields.answer,
      id: faq.sys.id
    }))
  }
}

export default contentfulPlugin
