<template>
  <div>
    <div class="d-flex align-center">
      <v-progress-circular
        v-if="loading"
        class="loader-main"
        size="280"
        width="15"
        indeterminate
      />
    </div>
    <div v-if="!loading">
      <TargetPage />
    </div>
    <p v-if="error" class="er">
      Something went wrong, please try again later
    </p>
  </div>
</template>

<script>
import api from '@/api'
import TargetPage from './views/TargetPage'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    TargetPage
  },
  data() {
    return {
      error: null,
      loading: true,
      user: null,
      targetPage: null,
      currentUser: null
    }
  },
  computed: {
    ...mapGetters(['getTargetPage', 'getUserDB'])
  },
  watch: {
    '$route'(value) {
      this.setIsNotFound(false)
    }
  },
  async created() {
    if (this.$route.name && this.$route.name !== 'Login' && this.$route.name !== 'SignUp' && this.$route.name !== 'Root') {
      this.setTargetPage(this.$route.fullPath)
    }
    try {
      this.$firebase.auth().onAuthStateChanged(async userFB => {
        // Logout
        if (!userFB && this.getUserDB) {
          this.setUserDB(null)
          this.$router.push({ name: 'Login' })
        }
        this.setUserFB(userFB)
        if (!userFB) {
          this.loading = false
          return
        }
        const { data, error } = await api.users.getUser()
        if (error) {
          this.loading = false
          return
        }
        this.setUserDB(data)
        const isUserAdmin = data.role === 'admin'
        this.setIsAdmin(isUserAdmin)
        if (this.getTargetPage) {
          const targetPage = this.getTargetPage
          this.setTargetPage(null)
          this.loading = false
          if (targetPage !== this.$route.fullPath) {
            this.$router.push(targetPage)
            return
          }
          return
        }
        this.loading = false
        if (this.$route.name !== 'Login') return
        this.$router.push({ name: 'Root' })
      })
    } catch (e) {
      console.error(e.message)
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'setTargetPage',
      'setUserFB',
      'setUserDB',
      'setIsAdmin',
      'setIsNotFound'
    ])
  }
}
</script>

<style lang="scss">
@import "@/assets/style/global.scss";

.loader-main {
  display: block !important;
  margin: 20% auto;
  color: var(--primary-text-color);
}
</style>
