<template>
  <div>
    <span v-if="label" class="label">{{ label }}</span>
    <v-text-field
      v-model="inputValue"
      :class="{ 'alternative' : alt }"
      class="blockreviews-input"
      dense
      flat
      autocomplete="off"
      hide-details
      :height="height"
      :solo="solo"
      :outlined="outlined"
      :color="color"
      :background-color="backgroundColor"
      :error="error"
      :placeholder="placeholder"
      :append-icon="appendIcon"
      :type="type"
      @keyup.enter="$emit('pressEnter')"
      @click:append="$emit('click:append')"
    >
      <span slot="prepend-inner" class="prefix">
        <slot />
      </span>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'BRInput',
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: String,
      required: false,
      default: '40'
    },
    solo: {
      type: Boolean,
      required: false,
      default: true
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'var(--secondary-text-color)'
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'var(--input-bg-color)'
    },
    error: {
      type: Boolean,
      default: false
    },
    alt: {
      type: Boolean,
      require: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    appendIcon: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: block;
  margin-bottom: 7px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
}

.blockreviews-input::v-deep .v-input__icon--append .v-icon,
.blockreviews-input::v-deep input::placeholder,
.blockreviews-input::v-deep input {
  color: var(--secondary-text-color);
}

.blockreviews-input::v-deep .v-input__control {
  border-radius: var(--border-radius-small);
}

.blockreviews-input::v-deep fieldset {
  border: none;
}

.blockreviews-input::v-deep.alternative .v-input__slot {
  background-color: var(--input-alt-bg-color);
}
</style>
