<template>
  <BRMenu
    :open-on-click="openOnClick"
    :open-on-hover="openOnHover"
    content-class="currencies-menu"
    :left="left"
  >
    <template #menu-activator>
      <slot name="activator-element" />
    </template>
    <template #menu-content>
      <v-list class="dropdown-menu-list" min-width="260">
        <router-link :class="['dropdown-menu-list-item', {'active' : !$route.params.slug}]" to="/">
          <v-list-item>
            <img
              alt="Cryptocurrency"
              class="currency-image"
              src="/img/currency/cc.svg"
            >
            <v-list-item-title>
              Cryptocurrency
            </v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link
          v-for="(currency, index) in $contentful.currencies"
          :key="index"
          :class="['dropdown-menu-list-item', {'active' : $route.params.slug === currency.slug}]"
          :to="{ name: 'CasinoCurrency', params: { slug: currency.slug } }"
        >
          <v-list-item>
            <img
              :alt="currency.name"
              :src="getCurrencyIcon(currency)"
              class="currency-image"
              height="28"
              width="28"
            >
            <v-list-item-title>
              {{ currency.name }}
            </v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </template>
  </BRMenu>
</template>

<script>
import BRMenu from '@ui/BRMenu.vue'

export default {
  name: 'CurrenciesMenu',
  components: {
    BRMenu
  },
  props: {
    openOnClick: {
      type: Boolean,
      required: false,
      default: false
    },
    openOnHover: {
      type: Boolean,
      required: false,
      default: false
    },
    left: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getCurrencyIcon(currency) {
      return currency.icon || '/img/currency/cc.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
.currencies-menu {
  .dropdown-menu-list {
    padding: 15px 13px;
  }

  .dropdown-menu-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius-small);
    font-weight: var(--font-weight-bold);

    &::after {
      display: none;
    }

    &:hover,
    &.active {
      background-color: var(--primary-bg-color);

      .v-list-item__title {
        color: var(--primary-text-color);
      }
    }
  }

  .currency-image {
    display: block;
    margin-right: 15px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    font-weight: var(--font-weight-normal);
    font-size: var(--font-base);
    background-color: var(--tertiary-bg-color);
  }

  .v-list {
    background-color: var(--content-bg-color);
  }

  .v-list-item {
    padding: 9px 13px;
    height: 46px;
    min-height: 46px;
  }

  .v-list-item__title {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-base);
    color: var(--tertiary-text-color);
  }
}
</style>
