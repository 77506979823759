import api from '@/api'
import calculateRating from '@/services/calculateRating'

const fetchReviewsRatings = async(reviews) => {
  // eslint-disable-next-line no-undef
  const cloneReviews = structuredClone(reviews)
  const reviewsIds = cloneReviews.map((review) => review.sys.id).join(',')
  const { data, error } = await api.reviews.getReviews(reviewsIds)

  if (error || !data) {
    return cloneReviews
  }

  data.forEach((review) => {
    const index = cloneReviews.findIndex((r) => r.sys.id === review.itemId)
    const totalRating = calculateRating(review.userReviews, cloneReviews[index].fields.totalRating)
    cloneReviews[index] = {
      ...cloneReviews[index],
      fields: {
        ...cloneReviews[index].fields,
        ...review,
        contentfulRating: cloneReviews[index].fields.totalRating,
        totalRating
      }
    }
  })
  return cloneReviews
}

export const fetchCasinoRatings = async(casinoId) => {
  const { data, error } = await api.reviews.getReviews(casinoId)

  if (error || !data) {
    return []
  }

  return data
}

export default fetchReviewsRatings
