<template>
  <v-btn
    :block="block"
    :class="{ dashed: dashed }"
    :color="color"
    :dark="dark"
    :disabled="isDisabled"
    :height="height"
    :href="href"
    :icon="icon"
    :loading="loading"
    :outlined="outlined"
    :plain="plain"
    :target="href ? '_blank' : '_self'"
    :text="text"
    :to="to"
    :type="type"
    :width="width"
    :x-large="xLarge"
    class="blockreviews-button"
    elevation="0"
    fill
    tile
    v-on="on"
    @click="handlerClick"
  >
    <span class="button-text"><slot /></span>
  </v-btn>
</template>

<script>
export default {
  name: 'BRButton',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: null
    },
    dashed: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'var(--primary-text-color)'
    },
    height: {
      type: String,
      required: false,
      default: '45'
    },
    width: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    href: {
      type: String,
      required: false,
      default: ''
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    },
    plain: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    on: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      loader: null
    }
  },
  computed: {
    isDisabled() {
      return !!this.seconds || this.disabled
    }
  },
  methods: {
    handlerClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/mixins";

.blockreviews-button::v-deep.dashed.v-btn--outlined {
  border: thin dashed !important;
  border-radius: var(--border-radius-default);
}

.blockreviews-button {
  border-radius: 5px;
}

.button-text {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  letter-spacing: normal;
  text-transform: none;
}

[data-theme="dark"] .theme--dark.v-btn.v-btn--has-bg,
[data-theme="dark"] .preview-btn.btn-active .button-text {
  color: var(--button-text-color);
}

[data-theme="dark"] .sort-reviews-button .button-text {
  color: var(--contrast-text-color);
}
</style>
