<template>
  <BRIcon
    class="logo"
    icon="logo"
    width="150"
    height="30"
    fill-color="var(--logo-color)"
  />
</template>

<script>
export default {
  name: 'TheLogo'
}
</script>

<style lang="scss" scoped>
.logo {
  height: 30px;
  max-width: 150px;
  transform: translateZ(0);
}
</style>
