<template>
  <ul class="socials-list">
    <li v-for="social in socials" :key="social.id" class="socials-list-item">
      <a :href="social.link" :aria-label="social.title">
        <BRIcon
          :icon="social.icon"
          fill-color="var(--primary-icon-color)"
          width="26"
          height="26"
        />
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SocialsList',
  props: {
    socials: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.socials-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  gap: 18px;
}
</style>
