import Vue from 'vue'
import VueRouter from 'vue-router'
import Fire from './plugins/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: () => import('./views/CasinoCategory'),
    meta: { title: 'Casino' }
  },
  {
    path: '/currency/:slug',
    name: 'CasinoCurrency',
    component: () => import('./views/CasinoCategory'),
    meta: { title: 'Casinos' }
  },
  {
    path: '/tag/:slug',
    name: 'CasinoTag',
    component: () => import('./views/CasinoCategory'),
    meta: { title: 'Casinos' }
  },
  {
    path: '/casino/:id',
    name: 'CasinoItemPage',
    component: () => import('./views/CasinoItemPage'),
    meta: { title: 'Casino' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('./views/Login'),
    meta: {
      title: 'Login',
      hideForAuth: true
    }
  },
  {
    path: '/airdrop',
    name: 'Airdrop',
    component: () => import('./views/Airdrop'),
    meta: { title: 'Airdrop' }
  },
  {
    path: '/airdrop/:id',
    name: 'AirdropItemPage',
    component: () => import('./views/AirdropItemPage'),
    meta: { title: 'Airdrop' }
  },
  {
    path: '/blog',
    name: 'Blog'
  },
  {
    path: '/top',
    name: 'TOP'
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('./views/About'),
    meta: { title: 'About us' }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('./views/Privacy'),
    meta: { title: 'Terms of use' }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('./views/Faq'),
    meta: { title: 'FAQ' }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('./views/ForgotPassword'),
    meta: { title: 'Forgot password' }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('./views/SignUp'),
    meta: {
      title: 'Sign Up',
      hideForAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('./views/Profile'),
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('./views/ErrorPage'),
    meta: { title: '404' }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach(async(to, from, next) => {
  const currentUser = await Fire.getCurrentUser()

  if (!currentUser && to.meta.requiresAuth) {
    next({ name: 'Login' })
    return
  }

  if (currentUser && to.meta.hideForAuth) {
    next({ name: 'Root' })
    return
  }

  next()
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Blockreviews'
  })
})

export default router
