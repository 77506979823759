import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import Contentful from './plugins/contentful'
import firebase from './plugins/firebase'
import VueCarousel from 'vue-carousel'
import './plugins/main'
import * as contentful from 'contentful'
import fetchReviewsRatings from '@/services/fetchReviewsRatings'
import VueSanitize from 'vue-sanitize'

(async() => {
  const client = contentful.createClient({
    space: process.env.VUE_APP_CONTENTFUL_SPACE,
    accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN
  })

  const result = await Promise.all([
    client.getEntries({ content_type: 'reviews', limit: 1000 }),
    client.getEntries({ content_type: 'currency', limit: 1000 }),
    client.getEntries({ content_type: 'airdrop', limit: 1000 }),
    client.getEntries({ content_type: 'ads', limit: 1000 }),
    client.getEntries({ content_type: 'privacyPolicyTermsOfUse', limit: 1000 }),
    client.getEntries({ content_type: 'tags', limit: 1000 }),
    client.getEntries({ content_type: 'faq', limit: 1000 })
  ])

  const [
    { items: reviews = [] },
    { items: currencies },
    { items: airdrops },
    { items: ads },
    { items: privacyPolicyTermsOfUse },
    { items: tags },
    { items: faq }
  ] = result

  Vue.use(Contentful, {
    reviews: await fetchReviewsRatings(reviews),
    currencies,
    airdrops,
    ads,
    privacyPolicyTermsOfUse,
    tags,
    faq
  })
  Vue.use(VueCarousel)
  Vue.prototype.$firebase = firebase
  Vue.config.productionTip = false
  Vue.use(VueSanitize)
  new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})()
