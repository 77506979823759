<template>
  <div v-if="popupOpen" class="cookie-popup">
    <button class="close-button" @click="closeCookiePopup">
      <BRIcon
        width="11"
        height="11"
        icon="close"
        fill-color="var(--default-icon-color)"
      />
    </button>
    <div class="title-wrapper">
      <BRIcon
        width="21"
        height="21"
        icon="cookie"
        class="cookie-icon"
      />
      <span class="cookie-title">We use Cookie</span>
    </div>
    <p class="text">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat autem molestiae facere aut
      fuga illo iusto asperiores aperiam, laudantium, libero expedita optio, vero facilis
      recusandae perspiciatis odio dolorum? Officia, assumenda!
      <router-link class="link" :to="{ name: 'Privacy' }">
        private policy
      </router-link>
    </p>
    <button class="accept-button" @click="acceptCookie">
      Accept Cookies
    </button>
  </div>
</template>

<script>
export default {
  name: 'CookiePopup',
  data() {
    return {
      popupOpen: false
    }
  },
  created() {
    const cookie = document.cookie.split('; ').find(item => item === 'cookie=accepted')

    this.popupOpen = !cookie
  },
  methods: {
    closeCookiePopup() {
      this.popupOpen = false
    },
    acceptCookie() {
      document.cookie = 'cookie=accepted'
      this.closeCookiePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.cookie-popup {
  position: fixed;
  right: 78px;
  bottom: 70px;
  z-index: 10;
  border-radius: var(--border-radius-default);
  padding: 35px 24px 26px;
  max-width: 275px;
  box-shadow: var(--box-shadow-default);
  text-align: center;
  background-color: var(--content-bg-color);

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
    gap: 15px;
  }

  .cookie-icon {
    stroke: var(--text-color);
  }

  .cookie-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h3);
    color: var(--text-color);
  }

  .text {
    margin-bottom: 20px;
    font-size: var(--font-base);
    color: var(--secondary-text-color);

    .link {
      color: var(--primary-text-color);
    }
  }

  .close-button {
    position: absolute;
    right: 14px;
    top: 16px;
    width: 11px;
    height: 11px;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 11px;
      height: 11px;
    }

    @include breakpoint-reverse(small) {
      right: 10px;
      top: 10px;
    }
  }

  .accept-button {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--primary-text-color);
  }

  @include breakpoint-reverse(small) {
    right: unset;
    bottom: 0;
    border-radius: 0;
    padding: 12px;
    width: 100vw;
    max-width: unset;
  }
}
</style>
