import * as users from './UsersEndpoints'
import * as comments from './CommentsEndpoints'
import * as likes from './LikesEndpoints'
import * as reviews from './ReviewsEndpoints'

export default {
  users,
  comments,
  likes,
  reviews
}
