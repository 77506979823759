<template>
  <div class="error-page">
    <div>
      <span class="error-code">404 ERROR</span>
      <span class="error-title">Looks like you go to the wrong page...</span>
      <p class="error-text">
        Just small description to tell your customers what you will see on this page and how it work with crypto casinos
        bla bla… will see on this page and how it work with crypto bla bla…
      </p>
    </div>
    <img src="/img/404-page-img.svg" class="error-img" alt="404 error">
  </div>
</template>

<script>
export default {
  name: 'ErrorPage'
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.error-page {
  @include container;

  display: grid;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 66px;
  text-align: center;

  @include breakpoint-reverse(small) {
    padding-top: 33px;
    padding-bottom: 37px;
  }
}

.error-code {
  display: block;
  margin-bottom: 22px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-h3);
  color: var(--primary-text-color);

  @include breakpoint-reverse(small) {
    margin-bottom: 11px;
  }
}

.error-title {
  display: block;
  margin-bottom: 16px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-h1);

  @include breakpoint-reverse(small) {
    margin: 0 auto 19px;
    max-width: 290px;
  }
}

.error-text {
  margin-bottom: 75px;
  max-width: 614px;
  font-size: var(--font-base);
  color: var(--secondary-text-color);

  @include breakpoint-reverse(small) {
    margin: 0 auto;
    max-width: 290px;
  }
}

.error-img {
  margin-top: 84px;
  max-width: 100%;

  @include breakpoint-reverse(small) {
    margin-top: 49px;
    width: 100%;
    max-width: 335px;
  }
}
</style>
