export default function throttle(func, timeout = 100) {
  let timer = null

  return function perform(args) {
    if (timer) return

    timer = setTimeout(() => {
      func(args)

      clearTimeout(timer)
      timer = null
    }, timeout)
  }
}
