const scrollBlock = {
  data() {
    return {
      scrollPosition: 0
    }
  },
  methods: {
    blockScroll() {
      this.scrollPosition = window.pageYOffset
      const app = document.querySelector('#app')
      app.style.top = `${-this.scrollPosition}px`
      app.style.position = 'fixed'
      app.style.width = '100%'
      document.documentElement.style.position = 'fixed'
      document.documentElement.style.height = '100%'
      document.documentElement.style.width = '100%'
    },
    async unblockScroll() {
      document.documentElement.style.position = ''
      document.documentElement.style.height = ''
      document.documentElement.style.width = ''
      const app = document.querySelector('#app')
      app.style.top = ''
      app.style.position = ''
      app.style.width = ''
      window.scrollTo(0, this.scrollPosition)
    }
  }
}

export default scrollBlock
