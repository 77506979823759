<template>
  <BRMenu
    :left="left"
    :open-on-click="openOnClick"
    :open-on-hover="openOnHover"
    content-class="tags-menu"
  >
    <template #menu-activator>
      <slot name="activator-element" />
    </template>
    <template #menu-content>
      <div class="tags-menu__card">
        <div class="tags-menu__card-header">
          <div
            v-for="category in tagCategories"
            :key="category"
            class="column"
          >
            {{ category }}
          </div>
        </div>
        <div class="tags-menu__card-content">
          <div
            v-for="category in tagCategories"
            :key="category"
            class="column"
          >
            <router-link
              v-for="tag in tags[category]"
              :key="tag.id"
              :class="['item', {'active' : $route.params.slug === tag.slug}]"
              :to="{ name: 'CasinoTag', params: { slug: tag.slug } }"
            >
              {{ tag.name }}
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </BRMenu>
</template>

<script>
import BRMenu from '@ui/BRMenu.vue'

export default {
  name: 'TagsMenu',
  components: { BRMenu },
  props: {
    openOnClick: {
      type: Boolean,
      required: false,
      default: false
    },
    openOnHover: {
      type: Boolean,
      required: false,
      default: false
    },
    left: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      tags: this.$contentful.tags
    }
  },
  computed: {
    tagCategories() {
      return Object.keys(this.tags).sort()
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-menu {
  &__card {
    padding: 8px 17px 18px;
    min-width: 380px;
  }

  &__card-header {
    display: grid;
    grid-template-columns: repeat(3, 150px);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h4);
    color: var(--text-color);

    .column {
      padding: 8px 22px;
    }
  }

  &__card-content {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 150px);

    .column .item {
      display: block;
      border-radius: var(--border-radius-small);
      padding: 11px 22px;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-base);
      white-space: nowrap;
      color: var(--tertiary-text-color);

      &:hover,
      &.active {
        color: var(--primary-text-color);
        background-color: var(--primary-bg-color);
      }
    }
  }
}
</style>
