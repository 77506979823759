import { mapActions } from 'vuex'

const changeTheme = {
  data() {
    return {
      isDarkTheme: false
    }
  },
  watch: {
    isDarkTheme(value) {
      const html = document.documentElement
      html.dataset.theme = value ? 'dark' : 'light'
      localStorage.setItem('dark-theme-selected', JSON.stringify(value))
      this.setIsDarkTheme(value)
    }
  },
  mounted() {
    const isDarkTheme = JSON.parse(localStorage.getItem('dark-theme-selected'))
    if (isDarkTheme) {
      this.isDarkTheme = isDarkTheme
      this.setIsDarkTheme(isDarkTheme)
    }
  },
  methods: {
    ...mapActions(['setIsDarkTheme'])
  }
}

export default changeTheme
