<template>
  <v-menu
    :content-class="`br-menu ${contentClass}`"
    :left="left"
    :open-on-click="openOnClick"
    :open-on-hover="openOnHover"
    close-delay="100"
    nudge-bottom="42"
  >
    <template #activator="{ on, attrs }">
      <div :class="['br-menu__activator', {'br-menu__activator_active': attrs['aria-expanded'] === 'true'}]" v-on="on">
        <slot name="menu-activator" />
      </div>
    </template>
    <slot name="menu-content" />
  </v-menu>
</template>
<script>

export default {
  name: 'BRMenu',
  props: {
    contentClass: {
      type: String,
      required: false,
      default: 'br-menu'
    },
    openOnClick: {
      type: Boolean,
      required: false,
      default: false
    },
    openOnHover: {
      type: Boolean,
      required: false,
      default: false
    },
    left: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.v-menu__content::v-deep.br-menu {
  margin-top: 11px;
  border-radius: var(--border-radius-default);
  box-shadow: var(--box-shadow-default);
  background-color: var(--content-bg-color);
}
</style>
