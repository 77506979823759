import api from '@/api'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userFB: null,
    userDB: null,
    targetPage: null,
    isAdmin: null,
    isEditor: null,
    isAppLoading: true,
    userLocation: null,
    isNotFound: false,
    isDarkTheme: false
  },
  getters: {
    getUserFB: state => state.userFB,
    getUserDB: state => state.userDB,
    getTargetPage: state => state.targetPage,
    getIsAdmin: state => state.isAdmin,
    getIsEditor: state => state.isEditor,
    getIsAppLoading: state => state.isAppLoading,
    getUserLocation: state => state.userLocation,
    getIsNotFound: state => state.isNotFound,
    getIsDarkTheme: state => state.isDarkTheme
  },
  mutations: {
    SET_USER_FB(state, userFB) {
      state.userFB = userFB
    },
    SET_USER_DB(state, userDB) {
      state.userDB = userDB
    },
    SET_TARGET_PAGE(state, targetPage) {
      state.targetPage = targetPage
    },
    SET_IS_ADMIN(state, isAdmin) {
      state.isAdmin = isAdmin
    },
    SET_IS_EDITOR(state, isEditor) {
      state.isEditor = isEditor
    },
    SET_IS_APP_LOADING(state, isAppLoading) {
      state.isAppLoading = isAppLoading
    },
    SET_USER_LOCATION(state, userLocation) {
      state.userLocation = userLocation
    },
    SET_IS_NOT_FOUND(state, isNotFound) {
      state.isNotFound = isNotFound
    },
    SET_IS_DARK_THEME(state, isDarkTheme) {
      state.isDarkTheme = isDarkTheme
    }
  },
  actions: {
    setUserFB({ commit }, userFB) {
      commit('SET_USER_FB', userFB)
    },
    setUserDB({ commit }, userDB) {
      commit('SET_USER_DB', userDB)
    },
    setTargetPage({ commit }, targetPage) {
      commit('SET_TARGET_PAGE', targetPage)
    },
    setIsAdmin({ commit }, isAdmin) {
      commit('SET_IS_ADMIN', isAdmin)
    },
    setIsEditor({ commit }, isEditor) {
      commit('SET_IS_EDITOR', isEditor)
    },
    setIsAppLoading({ commit }, isAppLoading) {
      commit('SET_IS_APP_LOADING', isAppLoading)
    },
    setUserLocation({ commit }, userLocation) {
      commit('SET_USER_LOCATION', userLocation)
    },
    setIsNotFound({ commit }, isNotFound) {
      commit('SET_IS_NOT_FOUND', isNotFound)
    },
    setIsDarkTheme({ commit }, isDarkTheme) {
      commit('SET_IS_DARK_THEME', isDarkTheme)
    },
    async onAuthStateChangedAction({
      commit,
      getters
    }, { authUser }) {
      if (!authUser && getters.getUserDB) {
        commit('SET_USER_DB', null)
        this.$router.push({ name: 'Login' })
      }

      if (!authUser) {
        commit('SET_IS_APP_LOADING', false)
        return
      }

      const {
        data,
        error
      } = await api.users.getUser()
      if (error) {
        commit('SET_IS_APP_LOADING', false)
        return
      }

      commit('SET_USER_DB', data)

      if (data.role === 'admin') {
        commit('SET_IS_ADMIN', true)
      }

      if (data.role === 'editor') {
        commit('SET_IS_EDITOR', true)
      }

      if (getters.getTargetPage) {
        const targetPage = getters.getTargetPage
        commit('SET_TARGET_PAGE', null)
        commit('SET_IS_APP_LOADING', false)
        this.$router.push(targetPage)
      } else {
        commit('SET_IS_APP_LOADING', false)
        this.$router.push('/')
      }
    }
  }
})
