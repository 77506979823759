import { privateApi } from '@/plugins/axios'

export const callPrivateApi = async(method, url, ...args) => {
  try {
    const { data } = await privateApi[method](url, ...args)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response?.data,
        status: response?.status
      }
    }
  }
}
